import React from "react";
import { Row, Col, Dropdown, Tooltip, Popconfirm, Typography, Icon } from "antd";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import { SearchOutlined } from "@ant-design/icons";

const SearchMenu = ({
  md = 10,
  dashboard = false,
  placeholder,
  value,
  onSearch,
  size = "large",
  light = true,
  onClick,
  btn = false,
  label,
  dropdown = false,
  menu = [],
  icon = false,
  secondaryBtn = false,
  secondaryBtnProps = {
    onClick: () => {},
    label: "",
    prefix: null
  },
  endProps,
  popConfirm,
  refreshBtn = false,
  onRefresh,
  refreshing = false,
  primaryBtn = true
}) => {
  return (
    <Row type="flex" align="middle" gutter={[20, 20]}>
      <Col md={md} span={icon ? 20 : 24}>
        <FormInput placeholder={placeholder} value={value} onChange={onSearch} prefix={<SearchOutlined />} containerClassname="mb-0" secondary={light} size={size} />
      </Col>
      {btn ? (
        icon ? (
          <Col md={14} span={4} className="text-end">
            {popConfirm ? (
              <Popconfirm
                title={
                  <Typography.Text>
                    Add Template? <br />
                    You will be redirected to <br />
                    create new template page.
                  </Typography.Text>
                }
                onConfirm={onClick}
                onCancel={() => console.log("no")}
                okText="Yes"
                cancelText="No"
                zIndex={1036}
                placement="left"
              >
                <i
                  className="fas fa-plus-circle fs-5 text-info-color"
                  // onClick={onClick}
                  type="button"
                />
              </Popconfirm>
            ) : (
              <Tooltip title={label}>
                <i className="fas fa-plus-circle fs-5 text-info-color" onClick={onClick} type="button" />
              </Tooltip>
            )}
          </Col>
        ) : (
          <Col
            md={secondaryBtn ? 14 : 8}
            lg={refreshBtn ? (dashboard ? 9 : 5) : secondaryBtn ? 14 : 7}
            xl={refreshBtn ? (dashboard ? 6 : 4) : secondaryBtn ? 14 : 7}
            xxl={refreshBtn ? (dashboard ? 4 : 3) : secondaryBtn ? 14 : 7}
            span={24}
            className="d-flex align-items-center flex-wrap gap-3"
          >
            {dropdown ? (
              <Dropdown overlay={menu}>
                <AppButton label={label} prefixIcon={<i className="fas fa-chevron-circle-down me-2" />} light={light} size={size} />
              </Dropdown>
            ) : (
              primaryBtn && <AppButton label={label} prefixIcon={<i className="fas fa-plus me-2" />} onClick={onClick} light={light} size={size} />
            )}
            {secondaryBtn && <AppButton light={light} size={size} {...secondaryBtnProps} />}
            {endProps && endProps}
          </Col>
        )
      ) : null}
      {onRefresh && (
        <Col span={2} xxl={1} className="text-center">
          <Tooltip title="Refresh">
            <AppButton label={<Icon type="sync" className="align-middle" spin={refreshing} />} onClick={onRefresh} light />
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

export default SearchMenu;
